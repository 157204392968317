import { isMobile } from "../utils/is-mobile";

function parseWKT( wktString ) {
	var polygonCoords = [];
	var coordinates = wktString.match( /-?\d+\.\d+\s-?\d+\.\d+/g );
  
	for ( var i = 0; i < coordinates.length; i++ ) {
		var parts = coordinates[ i ].split( /\s/ );
		var latLng = {
			lat: parseFloat( parts[1] ),
			lng: parseFloat( parts[0] ),
		};

		polygonCoords.push( latLng );

	}
  
	return polygonCoords;
}

async function parseWKTFromFile( filePath ) {
	const response = await fetch( filePath );
	const data = await response.text();
	return parseWKT( data );
}

const initFooterMap = async function( $block ) {
	const { Map } = await google.maps.importLibrary("maps");
	const $map = $block.find( "#footer-map" );

	if ( !$map.length ) { return false; }

	const $mapElements = $block.find( ".footer-map-coord-js" );

	var markers = [];

	async function loadFooterMap() {
		var styleArray = [
			{elementType: "geometry", stylers: [{color: "#f6f7f0"}]},
			{elementType: "labels.text.fill", stylers: [{color: "#949494"}]},
			{featureType: "poi", stylers: [{visibility: "off"}]},
			{featureType: "road", elementType: "geometry", stylers: [{color: "#d5d5d3"}]},
			{featureType: "road", elementType: "geometry.stroke", stylers: [{color: "#d5d5d3"}]},
			{featureType: "road", elementType: "labels", stylers: [{visibility: "off"}]},
			{featureType: "road.highway", elementType: "geometry", stylers: [{color: "#f6f7f0"}]},
			{featureType: "road.highway", elementType: "geometry.stroke", stylers: [{color: "#d5d5d3"}]},
			{featureType: "road.highway", elementType: "labels", stylers: [{visibility: "off"}]},
			{featureType: "transit", elementType: "geometry", stylers: [{color: "#c5d7ad"}]},
			{featureType: "transit.station", elementType: "labels", stylers: [{visibility: "off"}]},
			{featureType: "water", elementType: "geometry", stylers: [{color: "#c5d7ad"}]},
			{featureType: "water", elementType: "labels", stylers: [{visibility: "off"}]}
		];

		var winWidth = jQuery( window ).width();

		var zoom = 7;
		
		var map = new google.maps.Map( $map[0], {
			center: { lat: 34.7000, lng: -81.700 },
			zoom: zoom,
			mapTypeControl: false,
			zoomControl: true,
			styles: styleArray
		} );
		
		var wktGreenville = "/wp-content/themes/buildonyourland/src/js/polygons//greenville.wkt";
		var wktColumbia = "/wp-content/themes/buildonyourland/src/js/polygons//columbia.wkt";
		var wktAiken = "/wp-content/themes/buildonyourland/src/js/polygons//aiken.wkt";

		const greenvilleCoords = await parseWKTFromFile( wktGreenville );
		const columbiaCoords = await parseWKTFromFile( wktColumbia );
		const aikenCoords = await parseWKTFromFile( wktAiken );



		var greenvillePolygon = new google.maps.Polygon( {
			paths: greenvilleCoords,
			strokeColor: "#628533",
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: "#628533",
			fillOpacity: 0.35,
			map: map
		} );

		var columbiaPolygon = new google.maps.Polygon( {
			paths: columbiaCoords,
			strokeColor: "#628533",
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: "#628533",
			fillOpacity: 0.35,
			map: map
		} );

		var aikenPolygon = new google.maps.Polygon( {
			paths: aikenCoords,
			strokeColor: "#628533",
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: "#628533",
			fillOpacity: 0.35,
			map: map
		} );

		greenvillePolygon.setMap( null );
		columbiaPolygon.setMap( null );
		aikenPolygon.setMap( null );

		$mapElements.each( function( index, element ) {
			var marker = new google.maps.Marker( {
				position: { lat: parseFloat( element.dataset.lat ), lng: parseFloat( element.dataset.lng ) },
				map: map,
				icon: {
					url: element.dataset.marker
				},
			} );

			markers.push( marker );
		} );	

		markers.forEach( function( marker, index ) {
			if ( isMobile() ) {
				google.maps.event.addListener( marker, "click", function() {
					handleMarkerClick( index );
					smoothPanTo( marker.getPosition(), map );
				} );
			} else {
				google.maps.event.addListener( marker, "mouseover", function() {
					handleMarkerClick( index );
					smoothPanTo( marker.getPosition(), map );
				} );
			}
		} );

		function smoothPanTo( position, map ) {
			var start = Date.now();
			var duration = 150;
			var startLatLng = map.getCenter();
			var startLat = startLatLng.lat();
			var startLng = startLatLng.lng();
			var endLat = position.lat();
			var endLng = position.lng();
		
			function moveStep() {
				var progress = ( Date.now() - start ) / duration;
				if ( progress < 1 ) {
					var lat = startLat + progress * ( endLat - startLat );
					var lng = startLng + progress * ( endLng - startLng );
					var newCenter = new google.maps.LatLng( lat, lng );
					map.panTo( newCenter );
					requestAnimationFrame( moveStep );
				} else {
					map.panTo( position );
				}
			}
		
			moveStep();
		}

		function handleMarkerClick( markerIndex ) {
			map.setZoom( 7 );

			if ( markerIndex === 0 ) {
				greenvillePolygon.setMap( map );
				columbiaPolygon.setMap( null );
				aikenPolygon.setMap( null );
			} else if ( markerIndex === 1 ) {
				columbiaPolygon.setMap( map );
				greenvillePolygon.setMap( null );
				aikenPolygon.setMap( null );
			} else if ( markerIndex === 2 ) {
				aikenPolygon.setMap( map );
				columbiaPolygon.setMap( null );
				greenvillePolygon.setMap( null );
			}
		}
		
	}

	loadFooterMap();

};

themeUtils.loadBlock( initFooterMap, "footer", ".footer" );